.p-0 {
    padding: 0px;
}
.pd-20 {
    padding: 20px;
}
.pdtb-20 {
    padding: 20px 0;
}
.pdr-10 {
    padding-right: 10px;
}
.pdr-20 {
    padding-right: 20px;
}
.mrgr-5 {
    margin-right: 5px;
}
.mrgb-10 {
    margin-bottom: 10px;
}
.fr {
    float: right;
}
form, .clear-both {
    clear: both;
}