/* CSS reset */
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,fieldset,input,textarea,p,blockquote,th,td { 
  margin:0;
  padding:0;
}

html,body {
  height: 100%;
  margin:0;
  padding:0;
}

body{
  background: #f8f8f8;
}

#root {
  height: 100%;
}

.App {
  text-align: center;
  overflow: auto;
  position: relative;
  min-height: 100%;
}

.show-grid {
  padding: 20px 0px;
  margin: 0px;
}

.sign-user {
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  -o-border-radius: 20px;
  -ms-border-radius: 20px;
}

.white-box {
  background: #fff;
  box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
}

.sign-user .btn-toolbar {
  width: 180px;
  margin: 0 auto;
}

.register-tag{
  display: block;
}

.navbar {
  background: #F8F9FB;
  margin: 0px;
  border: 0px;
  border-radius: 0px;
}

.blue-nav-bar {
  background-color: #00B5EB;
}

.menu-navbar {
  min-height: 400px;
  height: calc(100% - 50px);
  position: absolute;
}

.menu-navbar li {
  margin-top: 0px !important;
}

.menu-navbar li.active a {
  color: #FFFFFF !important;
  background-color: #00B5E7 !important;
}

.navbar-header {
  background-color: #0093D9;
  width: 100%;
  margin: 0px !important;
}

.navbar-brand {
  padding: 9px 5px;
  margin-left: 0px !important;
}

nav .container {
  width: 100%;
  padding: 0px;
}

.navbar-nav {
  float: none;
  clear: both;
  width: 100%;
  margin: 0px;
}

.navbar-nav>li {
  float: none;
  clear: both;
  text-align: left;
}

.navbar li:first-child {
  border-top: 1px solid #CCC;
}

.navbar li {
  border-bottom: 1px solid #CCC;
}

.navbar-nav>li a {
  color: #6a6c6f;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 30px;
  padding: 10px 15px !important;
}

.logout-wrapper {
  float: right;
  padding: 0px;
}

.logout {
  position: relative;
}

.navbar-nav>li.logout a {
  padding-left: 40px !important;
}

.logout::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: url(/assets/icons/upload.svg);
  background-position: center center;
  background-size: 25px;
  background-repeat: no-repeat;
  height: 50px;
  width: 50px;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  margin: 0px;
  cursor: pointer;
}

.navbar-right {
  margin-right: 0px !important;
}

.dropdown-menu {
  min-width: 100px;
}

.table {
  border-bottom: 1px solid #dddddd;
  margin-top: 0px;
}

.table th {
  text-align: center;
  line-height: 30px !important;
}

.table td {
  vertical-align: middle !important;
}

.form-group {
  clear: both;
}

.switch-input input{
  opacity: 0;
}

.switch {
  display:inline-block;
  width:60px;
  height:30px;
  margin:8px;
  transform:translateY(50%);
  position:relative;
}
span.multiselect-native-select{position:relative}span.multiselect-native-select select{border:0!important;clip:rect(0 0 0 0)!important;height:1px!important;margin:-1px -1px -1px -3px!important;overflow:hidden!important;padding:0!important;position:absolute!important;width:1px!important;left:50%;top:30px}.multiselect-container{position:absolute;list-style-type:none;margin:0;padding:0}.multiselect-container .input-group{margin:5px}.multiselect-container .multiselect-reset .input-group{width:93%}.multiselect-container>li{padding:0}.multiselect-container>li>a.multiselect-all label{font-weight:700}.multiselect-container>li.multiselect-group label{margin:0;padding:3px 20px;height:100%;font-weight:700}.multiselect-container>li.multiselect-group-clickable label{cursor:pointer}.multiselect-container>li>a{padding:0}.multiselect-container>li>a>label{margin:0;height:100%;cursor:pointer;font-weight:400;padding:3px 20px 3px 40px}.multiselect-container>li>a>label.checkbox,.multiselect-container>li>a>label.radio{margin:0}.multiselect-container>li>a>label>input[type=checkbox]{margin-bottom:5px}.btn-group>.btn-group:nth-child(2)>.multiselect.btn{border-top-left-radius:4px;border-bottom-left-radius:4px}.form-inline .multiselect-container label.checkbox,.form-inline .multiselect-container label.radio{padding:3px 20px 3px 40px}.form-inline .multiselect-container li a label.checkbox input[type=checkbox],.form-inline .multiselect-container li a label.radio input[type=radio]{margin-left:-20px;margin-right:0}


.switch-slider {
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  border-radius:30px;
  box-shadow:0 0 0 2px #777, 0 0 4px #777;
  cursor:pointer;
  border:4px solid transparent;
  overflow:hidden;
   transition:.4s;
}
.switch-slider:before {
  position:absolute;
  content:"";
  width:100%;
  height:100%;
  background:#777;
  border-radius:30px;
  transform:translateX(-30px);
  transition:.4s;
}

.switch-input input:checked + .switch-slider:before {
  transform:translateX(30px);
  background:limeGreen;
}
.switch-input input:checked + .switch-slider {
  box-shadow:0 0 0 2px limeGreen,0 0 2px limeGreen;
}

.remove-investigator {
  font-weight: bold;
  height: 30px;
  width: 30px;
  display: block;
  float: right;
  padding: 0;
  text-align: center;
  cursor: pointer;
}

.modal-header {
  background: #f7f9fa;
}

.modal-title {
  font-size: 30px;
  font-weight: 300;
}

.modal-header .close {
  margin: 10px 0px;
  color: #d9534f;
  opacity: 1;
}

.modal-body .btn-info {
  background-color: #293a4a;
  border-color: #293a4a;
}

.primary-button {
  background-color: #293a4a !important;
  border-color: #293a4a !important;
  color: #FFFFFF !important;
}

.clickable-header {
  cursor: pointer;
}

.clickable-header span{
  padding: 0px 5px;
}

.search-form {
  position: relative;
}

.search-form .remove-search {
  position: absolute;
  right: 0px;
  line-height: 33px;
  width: 40px;
  cursor: pointer;
}

/* Loader css */

.lds-roller {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  position: absolute;
  top: 50%;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  margin: -3px 0 0 -3px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*****************/


.alert {
  margin-top: 10px; 
}